export const contactQuery = `// groq
*[_type == 'contact'][0] {
  seo {
    title,
    keywords,
    description
  },
  title,
  "_rawDescription": description,
  inputLabel,
  contact {
    title,
    tel,
    email
  },
  address {
    title,
    address1,
    address2,
    mainImage {
      asset-> {
        url
      }
    }
  }
}
`;
