import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SanityContactType } from '../utils/globalTypes';
import { ContactFirstSection, SEO } from '../components';
import { getClient } from '../utils/sanity.client';
import { contactQuery } from '../utils/querys/contact/query';

interface Props {
  data: {
    sanityContact: SanityContactType;
  };
}

const Contact = ({ data: { sanityContact } }: Props) => {
  const [contact, setContact] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(contactQuery);
      setContact(data);
    };
    if (contact === undefined && url.search === '?preview_mode=true') getData();
  }, [contact]);
  return (
    <Fragment>
      <SEO title={sanityContact.seo?.title || ''} description={sanityContact.seo?.description || ''} />
      <ContactFirstSection content={contact ? contact : sanityContact} />
    </Fragment>
  );
};

export default Contact;
export const pageQuery = graphql`
  query {
    sanityContact {
      seo {
        title
        keywords
        description
      }
      title
      _rawDescription
      inputLabel
      contact {
        title
        tel
        email
      }
      address {
        title
        address1
        address2
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
          url
        }
      }
    }
  }
`;
